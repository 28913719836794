import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, IPhone, img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3>{`What has been done to improve the user experience (UX) before the 2023 summer season?`}</h3>
    <br />
    <p>{`The user interface of Trailguide has evolved a lot over the years.`}</p>
    <br />
    <p>{`Little by little it has changed as we learn more and add more features. I think it has
improved the ease of use and efficiency of the app a lot. At the same time the visual
appearance has also improved.`}</p>
    <br />
    <p>{`Here is an overview of the changes we have made during our spring cleaning.`}</p>
    <br />
    <br />
    <br />
    <h4>{`Previous user interface and some of the problems with it`}</h4>
    <IPhone alt="User interface before redesign" src={img("/website/trailguide-iphone-navigation-rotneim.jpg")} className="float-right hidden sm:block flex-none w-64 mb-2 sm:ml-12" text="The old user interface" mdxType="IPhone" />
    <p>{`The navigation bar at the bottom had five buttons that were mostly unrelated to each
other. The usage frequency of each button was also very different.`}</p>
    <IPhone alt="User interface before redesign" src={img("/website/trailguide-iphone-navigation-rotneim.jpg")} className="block sm:hidden w-3/4 mx-auto my-8" text="The old user interface" mdxType="IPhone" />
    <br />
    <p>{`There were buttons for settings, sharing to social media, adjusting the transparancy of
the map overlays, selecting categories, and finally, one for getting the GPS location.`}</p>
    <br />
    <p>{`Mostly, only the location button that was used. And people who were curious of what was
underneath the tracks would use the transparency adjuster.`}</p>
    <br />
    <p>{`Selecting the current type of content required two clicks and it did not give a good
visual indication of what was selected.`}</p>
    <br />
    <p>{`If you wanted to show a list of the content, you would click the button along the left
edge. However, this was not easily reachable with your thumb, and required you to operate
the app with two hands.`}</p>
    <br />
    <p>{`The map and map overlay selector was opened using the button in the middle of the right
edge. However, the map and overlay selector dialog did not look good and had two
separate parts.`}</p>
    <br className="clear-both" />
    <br />
    <br />
    <h4>{`New navigation bar for easier one-hand operation`}</h4>
    <IPhone alt="New navigation bar for easier one-hand operation" src={img("/website/interface/iphone_new_interface.jpeg")} className="float-right hidden sm:block flex-none w-64 mb-2 sm:ml-12" text="The new user interface" mdxType="IPhone" />
    <p>{`The new navigation bar at the bottom now only has three buttons. One to show the
`}<strong parentName="p">{`list`}</strong>{` of content, one to go back to the `}<strong parentName="p">{`map`}</strong>{`, and one to get your current
`}<strong parentName="p">{`location`}</strong>{`.`}</p>
    <IPhone alt="New navigation bar for easier one-hand operation" src={img("/website/interface/iphone_new_interface.jpeg")} className="block sm:hidden w-3/4 mx-auto my-8" text="The new user interface" mdxType="IPhone" />
    <br />
    <p>{`The navigation bar can now easily be operated using your thumb holding the phone with
one hand.`}</p>
    <br />
    <p>{`The old button used for showing the list of content is now removed and replaced by the
navigation bar button. Any element you can remove is a good thing.`}</p>
    <br />
    <p>{`The category selector has now been moved to the top of the app. You can easily see what
content is currently selected, and what other type of content is available.`}</p>
    <br />
    <p><strong parentName="p">{`The category selector can be hidden out of the way by dragging it to the right. The app
will remember this when you reopen it.`}</strong></p>
    <br />
    <p>{`The map selector has also been moved further up, below the category selector. The
thinking is that this is not used very much and I wanted to move it out of the way.`}</p>
    <br className="clear-both" />
    <br />
    <br />
    <h4>{`The new map selector`}</h4>
    <IPhone alt="The map selector" src={img("/website/interface/iphone_maps_overlays.jpeg")} className="float-right hidden sm:block flex-none w-64 mb-2 sm:ml-12" text="The new map selector" mdxType="IPhone" />
    <p>{`All maps and map detail selections are now in the same dialog. No extra clicking is
needed to access these functions.`}</p>
    <IPhone alt="The map selector" src={img("/website/interface/iphone_maps_overlays.jpeg")} className="block sm:hidden w-3/4 mx-auto my-8" text="The new map selector" mdxType="IPhone" />
    <br />
    <p>{`The dialog occupies the bottom half of the screen so that it can easily be operated using
one hand if needed.`}</p>
    <br />
    <p>{`Scrolling the dialog reveals options to show additional details on the map
such as `}<strong parentName="p">{`MTB singletracks`}</strong>{` and `}<strong parentName="p">{`cycle routes`}</strong>{` from OpenStreetMap.`}</p>
    <br />
    <p>{`In addition to the `}<strong parentName="p">{`biking heatmap`}</strong>{` from Strava we have now added separate heatmaps for
`}<strong parentName="p">{`running`}</strong>{`, `}<strong parentName="p">{`skiing`}</strong>{`, and `}<strong parentName="p">{`water`}</strong>{` activities.`}</p>
    <br />
    <p>{`If you want to see the content of the map more clearly, you can set the map itself
to be `}<strong parentName="p">{`grayscale`}</strong>{`. The content will be shown in full color.`}</p>
    <br />
    <p>{`If you feel really advanced and are hunting for unknown and secret trails, you can
`}<strong parentName="p">{`split`}</strong>{` the map in two with one half showing satellite photos, and the other half
showing the map.`}</p>
    <br className="clear-both" />
    <br />
    <br />
    <h4>{`The new menu and settings dialog`}</h4>
    <IPhone alt="The menu" src={img("/website/interface/iphone_menu.jpeg")} className="float-right hidden sm:block flex-none w-64 mb-2 sm:ml-12" text="Combined menu and settings" mdxType="IPhone" />
    <p>{`Since we removed the settings button from the navigation bar, we needed to find a new
home for the settings. We decided to combine the settings and drop-down menu. It is
accessed by clicking the avatar image in the top right corner.`}</p>
    <IPhone alt="The menu" src={img("/website/interface/iphone_menu.jpeg")} className="block sm:hidden w-3/4 mx-auto my-8" text="Combined menu and settings" mdxType="IPhone" />
    <br />
    <p>{`You have access to settings for `}<strong parentName="p">{`language`}</strong>{`, toggling the map `}<strong parentName="p">{`zoom icons`}</strong>{`, and
turning on `}<strong parentName="p">{`navigation mode`}</strong>{` of the app.`}</p>
    <br />
    <p>{`If you want even more space for the map, you can hide the `}<strong parentName="p">{`navigation bar icon labels`}</strong>{`.`}</p>
    <br />
    <p>{`If you are `}<strong parentName="p">{`left handed`}</strong>{`, you can flip the navigation bar left-right for easier
left-hand operation.`}</p>
    <br />
    <p>{`Further there are buttons for making a `}<strong parentName="p">{`donation`}</strong>{` to the operation of Trailguide, and
the button to `}<strong parentName="p">{`share`}</strong>{` the current map position on social media.`}</p>
    <br />
    <p>{`The `}<strong parentName="p">{`current version`}</strong>{` you are using is shown in the bottom right corner. It shows the
`}<strong parentName="p">{`date and time`}</strong>{` it was built.`}</p>
    <br className="clear-both" />
    <br />
    <br />
    <h4>{`The content category selector`}</h4>
    <p>{`The category selector is now located at the top of the screen below the search field.
You can move the selector left and right to uncover what is available and you only need
one click to select the current category.`}</p>
    <div className="sm:flex justify-around mt-12">
  <IPhone alt="The category selector" src={img("/website/interface/iphone_select_category.jpeg")} className="w-3/4 mx-auto sm:w-1/3" text="Click to select content" mdxType="IPhone" />
  <IPhone alt="Hide the category selector" src={img("/website/interface/iphone_hide_categories.jpeg")} className="w-3/4 mx-auto sm:w-1/3 mt-8 sm:mt-0" text="Hide the category selector" mdxType="IPhone" />
    </div>
    <br />
    <p>{`If you seldom change the content category and you want more space for the map, you can
hide the category selector by dragging it all the way to the right of the screen. The
app will remember this when you reopen it.`}</p>
    <br className="clear-both" />
    <br />
    <br />
    <h4>{`New navigation mode`}</h4>
    <p>{`We renamed the old `}<strong parentName="p">{`follow-me mode`}</strong>{` to `}<strong parentName="p">{`navigation mode`}</strong>{` and added a compass
arrow and the option to show heading up instead of north up.`}</p>
    <br />
    <p>{`You activate the navigation mode from the settings menu, or by `}<strong parentName="p">{`clicking and holding the
location button in the nav-bar for more than one second`}</strong>{`. You switch between
`}<strong parentName="p">{`north-up`}</strong>{` or `}<strong parentName="p">{`heading-up`}</strong>{` by clicking the compass icon just below the map
selector button.`}</p>
    <br />
    <p><em parentName="p">{`NOTE 1: If you are in normal mode and long-click the location icon the app will NOT be
able to access the compass device on the phone (due to built-in security restrictions in
the phone). Click the location button again (not long) and it will be able to access the
compass device.`}</em></p>
    <br />
    <p><em parentName="p">{`NOTE 2: Your phone must have a compass device for this to work, and the heading
indication is only as good as your compass. The arrow will not be precise (if you
suffer from a condition causing you to go crazy because it is not pointing exactly in
the right direction, don't use it).`}</em></p>
    <div className="sm:flex justify-around mt-12">
  <IPhone alt="Navigation mode" src={img("/website/interface/iphone_navigate_rotate.jpeg")} className="w-3/4 mx-auto sm:w-1/3" text="Navigation mode with heading up" mdxType="IPhone" />
  <IPhone alt="Breadcrumbs showing your path" src={img("/website/interface/iphone_breadcrumbs.jpeg")} className="w-3/4 mx-auto sm:w-1/3 mt-8 sm:mt-0" text="Breadcrumbs showing your path" mdxType="IPhone" />
    </div>
    <br />
    <br />
    <p>{`To make it even easier to navigate, the app will also leave `}<strong parentName="p">{`"breadcrumbs"`}</strong>{` indicating
where you have been moving for the last hour. The color of the breadcrumbs will change
from red hot, to orange, to yellow, to green, to blue, to purple - depending on the age of
the breadcrumb.`}</p>
    <br />
    <p>{`With the combination of these breadcrumbs and use of `}<strong parentName="p">{`heading-up`}</strong>{`, you should have no
problem deciding which way to go when you reach a fork in the path.`}</p>
    <br />
    <p><em parentName="p">{`NOTE 3: The location indicator will `}<strong parentName="em">{`pulsate`}</strong>{` each time it receives new location data from
the phone. If you see it stop blinking for an extended period but you are still moving,
`}<strong parentName="em">{`try clicking the navigate button to reactivate it`}</strong>{`.`}</em></p>
    <br className="clear-both" />
    <br />
    <h4>{`Tap-tap-hold-and-drag to zoom`}</h4>
    <p>{`To improve one-handed operation, we added the possibility to zoom the map by
double-clicking, holding, and dragging the map (tap-tap-hold-drag).`}</p>
    <br />
    <p><em parentName="p">{`NOTE 1: On iPhone this gesture normally triggers a built-in magnifying glass. I had
to implement a hack to make it work, so it might not be 100% stable.`}</em></p>
    <br />
    <p><em parentName="p">{`NOTE 2: If you drag the map multiple times within a short time, you might trigger the
tap-tap-hold-drag function and the map might appears frozen. Try dragging the map with
one finger to "release" it (rage-clicking might also work).`}</em></p>
    <br className="clear-both" />
    <br />
    <p>{`I wish you a fantastic summmer with great outdoor adventures :)`}</p>
    <br />
Bjørn Jarle Kvande
    <br />
Developer in Chief
    <br />
Trailguide
    <br />
    <p><a parentName="p" {...{
        "href": "/coding"
      }}>{`Blog`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      